import React from 'react'

function NotFound() {
  return (
    <div className='notfound'>
      <h1>Not Found</h1>
    </div>
  )
}

export default NotFound